import React from 'react'
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import { useMutation, useQueryClient } from "react-query";
import AuthService from "../../../services/authServices";
import { useState } from "react";
import { useAuth } from "../../../hooks/useAuth";
import { toast } from "react-toastify";
import { LoadingButton, SimpleButton } from "../../../GenericComponent/Buttons";

const ForgotPassword = () => {
    const { login, loginMutations } = useAuth();
    // states
    const [credentials, setCredentials] = useState({
      email: "",
    });

    const onLoginClick = (e) => {
      e.preventDefault();
      if (!credentials.email) {
        toast.error("Email is required");
      }
      else if(!credentials.email.includes('@')){
        toast.error("Enter valid Email");
      }
     //   login(credentials);
      else
        toast.success(`Trying to send otp to: ${credentials.email}`, {autoClose:4000})
    };
  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="5">
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  <Form>
                    <h1>Forgot Password?</h1>
                    <p className="text-muted">Enter you email to get OTP</p>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-user"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        placeholder="User E-mail"
                        autoComplete="email"
                        name="email"
                        value={credentials.email}
                        onChange={(e) =>
                          setCredentials({
                            ...credentials,
                            [e.target.name]: e.target.value,
                          })
                        }
                      />
                    </InputGroup>
                   
                    <Row>
                      <Col xs="6">
                        <LoadingButton
                          onClick={onLoginClick}
                          loading={loginMutations.isLoading}
                        >
                          Send OTP
                        </LoadingButton>

                        {/* <LaddaButton
                          className="btn btn-primary btn-ladda"
                          loading={loginMutations.isLoading}
                          onClick={onLoginClick}
                          data-color="blue"
                          data-style={SLIDE_LEFT}
                        >
                          Submit!
                        </LaddaButton> */}
                      </Col>
                    </Row>
                  </Form>
                  <div className="text-muted mt-3">Remember your password?<a href="#/login"> Login Here</a></div>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ForgotPassword