import { request } from "../base";

export default class Auth {
  static SignInWithEmailAndPass(data) {
    return request({
      url: "users/v1/user/login",
      method: "post",
      data: data,
    });
  }
}
