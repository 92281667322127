import React, { Component, useEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
// import { renderRoutes } from 'react-router-config';
import "./App.scss";
// import "node_modules/video-react/dist/video-react.css";

import { useAuth } from "./hooks";
import { ForgotPassword } from "./views/Pages";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login/Login"));
const Register = React.lazy(() => import("./views/Pages/Register"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));
// const ForgotPassword = React.lazy(()=> import("./views/Pages/ForgotPassword"))
const App = (props) => {
  const { user } = useAuth();

  return (
    <HashRouter>
      <React.Suspense fallback={loading()}>
        <Switch>
          {!user && (
            <>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/register"
                name="Register Page"
                render={(props) => <Register {...props} />}
              />
              <Redirect from="/" to="/login" />
              
              {/* Made by ayush forgot password */}
              <Route
                exact
                path="/forgotpassword"
                name="Forgot Password Page"
                render={(props)=><ForgotPassword {...props} />}
              />
            </>
          )}
          <Route
            exact
            path="/404"
            name="Page 404"
            render={(props) => <Page404 {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={(props) => <Page500 {...props} />}
          />
          {user && (
            <Route
              path="/"
              name="Home"
              render={(props) => <DefaultLayout {...props} />}
            />
          )}
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
};

export default App;
