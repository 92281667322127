import Axios from "axios";
import Env from "../../environment";

export const baseURL = Env().baseUrl;

export const client = Axios.create({ baseURL });

export const request = async ({ ...options }) => {
  const user = JSON.parse(localStorage.getItem("commeat-user"));
  if (user) {
    client.defaults.headers.common["x-access-token"] = user.sessionToken;
    client.defaults.headers.common["x-access-user"] = user.account_id;
  }
  const onSuccess = (response) => response;
  const onError = (error) => {
    // optionally catch errors and add additional logging here
    return error;
  };

  try {
    const response = await client(options);
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};
