import React, { forwardRef } from "react";
import { AppSwitch } from "@coreui/react";
import "./style.css";
const SwitchButton = forwardRef((props, ref) => {
  return (
    <AppSwitch
      style={{ zIndex: 0 }}
      ref={ref}
      className={"mx-1"}
      color={"success"}
      variant={"pill"}
      outline={"alt"}
      checked={props.checked}
      label
      {...props}
    />
  );
});

export default SwitchButton;
