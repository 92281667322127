import { Button } from "reactstrap";

import React from "react";

function SimpleButton(props) {
  const { children, ...remainProps } = props;

  return (
    <Button color="primary" className="px-4" {...remainProps}>
      {children}
    </Button>
  );
}

export default SimpleButton;
