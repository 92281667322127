import React, { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import AuthService from "../services/authServices";

export const useAuth = () => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("commeat-user")) || null
  );
  const [loading, setLoading] = useState(false);

  // mutations
  const loginMutations = useMutation(
    "loginMutation",
    AuthService.SignInWithEmailAndPass,
    {
      onSuccess: (resp) => {
        if (resp.data && resp.data.data && resp.data.data.type == '2') {
          toast.success("Login Successfully!");
          setUser(resp.data.data);
          localStorage.setItem("commeat-user", JSON.stringify(resp.data.data));
          window.location.reload();
        }
        else if(resp.data && resp.data.data && resp.data.data.type != 2) toast.error("You dont have access to admin.");
        else{
          toast.warn("Invalid ID and Password Combination");
        }
      },
      onError: (error) => {
        toast.error("Something went wrong!");
        console.log(error);
      },
    }
  );

  const login = async (data, login_type = "email") => {
    await loginMutations.mutateAsync({ ...data, login_type });
  };

  const signOut = () => {
    
    localStorage.removeItem("commeat-user");
    setUser(null);
    window.location = "#/login";
    window.location.reload();
  };

  return { login, user, loginMutations, signOut };
};
