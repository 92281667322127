import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import { useMutation, useQueryClient } from "react-query";
import AuthService from "../../../services/authServices";
import { useState } from "react";
import { useAuth } from "../../../hooks/useAuth";
import { toast } from "react-toastify";
import { LoadingButton, SimpleButton } from "../../../GenericComponent/Buttons";

const Login = () => {
  const { login, loginMutations } = useAuth();
  // states
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  const onLoginClick = (e) => {
    e.preventDefault();
    if (!credentials.email && !credentials.password) {
      toast.error("Email and Password is required");
    }
    login(credentials);
  };

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="5">
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  <Form>
                    <h1>Login</h1>
                    <p className="text-muted">Sign In to your account</p>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-user"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        placeholder="Username"
                        autoComplete="username"
                        name="email"
                        // value={credentials.email}
                        onChange={(e) =>
                          setCredentials({
                            ...credentials,
                            [e.target.name]: e.target.value,
                          })
                        }
                      />
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        name="password"
                        // value={credentials.password}
                        onChange={(e) =>
                          setCredentials({
                            ...credentials,
                            [e.target.name]: e.target.value,
                          })
                        }
                      />
                    </InputGroup>
                    <Row>
                      <Col xs="6">
                        <LoadingButton
                          onClick={onLoginClick}
                          loading={loginMutations.isLoading}
                        >
                          Login
                        </LoadingButton>

                        {/* <LaddaButton
                          className="btn btn-primary btn-ladda"
                          loading={loginMutations.isLoading}
                          onClick={onLoginClick}
                          data-color="blue"
                          data-style={SLIDE_LEFT}
                        >
                          Submit!
                        </LaddaButton> */}
                      </Col>
                      
                      {/* Added Forgot Password Page */}
                      {/* <Col xs="6" className="text-right">
                        <Link to="/forgotpassword">Forgot password?</Link>
                      </Col> */}
                    </Row>
                  </Form>

                  {/* Added register link */}
                  {/* <div className="text-muted mt-3">Didn't have account?<a href="#/register"> Register Here</a></div> */}
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
