import React from "react";
import LaddaButton, { SLIDE_LEFT } from "react-ladda";
import "ladda/dist/ladda-themeless.min.css";

function LoadingButton(props) {
  const { children, variant, classes, ...remainProps } = props;

  return (
    <LaddaButton
      className={`btn btn-ladda btn-${variant || "primary"} ${classes}`}
      data-color="blue"
      data-style={SLIDE_LEFT}
      {...remainProps}
    >
      {children}
    </LaddaButton>
  );
}

export default LoadingButton;
