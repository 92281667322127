export const RELEASE_VERSION = "1.0.0";

export const _Environments = {
  production: {
    env: "production",
    baseUrl: "https://production-api.commeat.com/",
    release: RELEASE_VERSION,
    cdn: "http://cdn.commeat.com/",
  },
  development: {
    env: "development",
    baseUrl: "https://production-api.commeat.com/",
    release: RELEASE_VERSION,
    logs: true,
    cdn: "http://cdn.commeat.com/",
    defaultImage:
      "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
  },
};

const env = process.env.ENV || "development";

export default () => {
  return _Environments[env];
};
